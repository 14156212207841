/* The following line can be included in a src/App.scss */

@import "custom";

@import "~bootstrap/scss/variables";

body {
  background-image: url(/assets/waves.svg);
  background-size: cover;
}
// input,
// select {
//   border-color: #0677c7 !important;
// }
.form-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 6px;
  max-width: 420px !important;
  min-width: 420px !important;
  @media (max-width: 576px) {
    min-width: 320px !important;
  }
}

.form-container-multiple {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 6px;
  max-width: 722px !important;
  min-width: 420px !important;
  @media (max-width: 576px) {
    min-width: 320px !important;
  }
}
.declined-form-container-multiple-details {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 6px;
  margin-bottom: 24px;
  padding: 17px;
  // max-width: 66% !important;
  max-width: 756px !important;
  min-height: 151px !important;
  // max-height: 400px !important;
  min-width: 180px !important;
  @media (max-width: 576px) {
    min-width: 120px !important;
  }
}

.success-form-container-multiple-details {
  text-wrap: wrap;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 6px;
  margin-bottom: 24px;
  padding: 17px;
  min-height: 151px !important;
  // max-height: 400px !important;
  min-width: 600px !important;
  max-width: 66% !important;
  @media (max-width: 576px) {
    min-width: 120px !important;
  }
}

.form-container-multiple-details {
  margin-bottom: 24px;
  padding: 17px;
  max-width: 301px !important;
  min-height: 151px !important;
  max-height: 400px !important;
  min-width: 180px !important;
  @media (max-width: 576px) {
    min-width: 120px !important;
  }
}

.form-button {
  text-align: center;
  width: 100%;
  height: 50px;
}
.form-button-link {
  text-align: center;
  // width: 100%;
  padding-top: 10px;
  height: 50px;
  width: "55%";
  text-decoration: "none";
  color: var(--bs-link-color);
  border-width: "0px";
}

.form-label {
  margin-bottom: 0 !important;
}

.form-spacing {
  padding-bottom: ($spacer * 0.75) !important;
}
.form-spacing-healthplan {
  padding-bottom: ($spacer * 0.75) !important;
  max-width: 390px !important;
}
.success-container {
  margin-top: -100px;
  @media (max-width: 768px) {
    margin-top: 16px;
  }
}

.decline-container {
  margin-top: -100px;
  @media (max-width: 768px) {
    margin-top: 16px;
  }
}

.paragraph-max-width {
  max-width: 680px;
}

.show-hide {
  border-left: 0 !important;
  border-color: #ced4da !important;
  box-shadow: none !important;
  background: none !important;
  color: var(--bs-secondary) !important;
  &:hover {
    color: var(--bs-primary) !important;
  }
}

.toast-container {
  min-height: 240px;
  min-width: auto;
  top: 32px;
  right: 32px;
  zindex: 999;
  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }
}

.error-toast {
  background: #e87070;
}
.subtext {
  color: var(--bs-subtext);
}

.support-list {
  color: #5d5d5d !important;
  font-size: 14px !important;
}

.health-plan-missing {
  font: normal normal 600 14px/20px Open Sans;
}
